<template>
  <div class="tfoms-info3">
    <div class="tfoms-info3__header">
      <div class="tfoms-info3__header-title" :class="color + '--text'">
        {{ title }}
      </div>
    </div>

    <div class="tfoms-info__header-subtitle">{{ subTitle }}</div>

    <div class="tfoms-info3__content d-flex">
      <div class="tfoms-info3__block mr-3 pt-2">
        <div class="tfoms-info3__item">
          <div class="tfoms-info3__item-name">План</div>
          <div class="tfoms-info3__item-value">{{ value | numberSplit }}</div>
        </div>
        <div class="tfoms-info3__item">
          <div class="tfoms-info3__item-name">Факт</div>
          <div class="tfoms-info3__item-value">{{ value3 | numberSplit }}</div>
        </div>
      </div>
      <div class="tfoms-info3__block" style="width: 80px">
        <div class="tfoms-info3__content-value">
          <template v-if="value2"> {{ value2 }}% </template>
          <template v-else> 0% </template>
        </div>
        <div class="tfoms-info3__header-status">Выполнение плана</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "view-tfoms-info32",
  props: {
    title: {
      type: String,
      default: "ПРИКРЕПЛЕННОЕ НАСЕЛЕНИЕ",
    },
    subTitle: {
      type: String,
      default: "Человек",
    },
    value: {
      type: Number,
      default: 0,
    },
    value2: {
      type: Number,
      default: 0,
    },
    value3: {
      type: Number,
      default: 0,
    },
    diff: {
      type: Number,
      default: 134,
    },
    date: {
      type: String,
      default: "октябрь",
    },
    isPercent: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "primary",
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss">
.tfoms-info3 {
  padding: 20px;
  background-color: var(--v-white-base);
  border-radius: 10px;

  &__item {
    display: flex;
    color: var(--v-blue-grey-base);
    &-name {
      display: flex;
      align-items: center;
      font-size: 12px;
      margin-right: 10px;
    }
    &-value {
      font-size: 20px;
    }
  }

  &__block {
    width: 170px;
  }
  &__header {
    &-title {
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
    }
    &-subtitle {
      font-size: 12px;

      color: var(--v-blue-grey-base);
    }
    &-status {
      color: var(--v-blue-grey-base);
      letter-spacing: -0.12px;
      font-size: 12px;
      line-height: 90%;
      max-width: 100px;
    }
  }

  &__content {
    &-value {
      font-size: 30px;
      color: var(--v-blue-grey-base);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-diff {
      font-size: 16px;

      font-weight: 600;
    }
    &-date {
      font-size: 12px;
      color: var(--v-blue-grey-base);
      font-weight: 600;
    }
    &-meta {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
</style>
